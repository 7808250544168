import { Stack, Step, StepLabel, Stepper, styled, Typography, useMediaQuery } from '@mui/material';
import { DialogConfirm } from 'app/components/elements/DialogConfirm';
import { LayoutLanding } from 'app/components/templates/LayoutLanding';
import { PrimaryButton } from 'app/components/elements/ButtonCustom';
import { useCallback, useEffect, useState } from 'react';
import { FormInfo } from 'app/pages/Introduction/components/FormInfo';
import { FormPassword } from 'app/pages/Introduction/components/FormPassword';
import { FormName } from 'app/pages/Introduction/components/FormName';
import { FormBirthday } from 'app/pages/Introduction/components/FormBirthday';
import { FormAddress } from 'app/pages/Introduction/components/FormAddress';
import { FormTerm } from 'app/pages/Introduction/components/FormTerm';
import { FormQuestion } from 'app/pages/Introduction/components/FormQuestions';
import { FormProvider, useForm } from 'react-hook-form';
import { StorageServices } from 'services/storage';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { omit } from 'lodash';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { SelectOptions } from 'app/components/elements/InputSelect';
import { checkAgeIsUnder18 } from 'utils/checkAgeIsUnder18 ';

type TypeSteps = {
  label: string;
  iconActive: string;
  iconInactive: string;
  iconMobileActive: string;
  iconMobileInactive: string;
};
const steps: TypeSteps[] = [
  {
    label: 'Login',
    iconActive: '/icons/icon_step1-active.png',
    iconInactive: '/icons/icon_step1-active.png',
    iconMobileActive: '/icons/mobile-step-1-active.png',
    iconMobileInactive: '/icons/mobile-step-1-active.png'
  },
  {
    label: 'Name',
    iconActive: '/icons/icon_step2-inactive.png',
    iconInactive: '/icons/icon_step2-active.png',
    iconMobileActive: '/icons/mobile-step-2-active.png',
    iconMobileInactive: '/icons/mobile-step-2-inactive.png'
  },
  {
    label: 'Birth date & keywords',
    iconActive: '/icons/icon_step3-inactive.png',
    iconInactive: '/icons/icon_step3-active.png',
    iconMobileActive: '/icons/mobile-step-3-active.png',
    iconMobileInactive: '/icons/mobile-step-3-inactive.png'
  },
  {
    label: 'Location',
    iconActive: '/icons/icon_step4-inactive.png',
    iconInactive: '/icons/icon_step4-active.png',
    iconMobileActive: '/icons/mobile-step-4-active.png',
    iconMobileInactive: '/icons/mobile-step-4-inactive.png'
  },
  {
    label: 'Terms and Conditions',
    iconActive: '/icons/icon_step5-inactive.png',
    iconInactive: '/icons/icon_step5-active.png',
    iconMobileActive: '/icons/mobile-step-5-active.png',
    iconMobileInactive: '/icons/mobile-step-5-inactive.png'
  }
];
export const ROLES = {
  STUDENT: 'STUDENT',
  BLOOMBASSADOR: 'BLOOMBASSADOR',
  ADMIN: 'ADMIN'
};

export type TypeFormSignUp = {
  password: string;
  confirmPassword: string;
  name: string;
  keywords: string[];
  birthday: string;
  country: SelectOptions;
  city: SelectOptions;
  school: string;
  major: string;
  subjects: string[];
  isAgreeTermsConditions?: boolean;
  role: string;
};

const storage = new StorageServices();

const formValue = storage.getFormSignUp() ? JSON.parse(storage.getFormSignUp() || '{}') : {};

const defaultValue = {
  ...formValue,
  birthday: formValue.birthday ? dayjs(formValue.birthday) : undefined
};

export const Introduction = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:900px)');
  const [activeStep, setActiveStep] = useState<number>(Number(storage.getStepIntroductions()) || 0);
  const [initForm] = useState<TypeFormSignUp>(defaultValue);
  const methods = useForm<TypeFormSignUp>({ defaultValues: initForm });
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    const email = storage.getEmailCookie();
    const phone = storage.getPhoneCookie();
    if (!email || !phone) return navigate('/sign-up');
    storage.setStepIntroductions(activeStep);
    // eslint-disable-next-line
  }, [activeStep]);
  const handleNext = useCallback((data: TypeFormSignUp) => {
    storage.setFormSignUp(data);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  const postSignUp = useCallback(
    async (params: TypeFormSignUp) => {
      await axios.post('/auth/signup', params);
      storage.removeFormSignUp();
      storage.removeStepIntroductions();
      storage.removeEmailCookie();
      storage.removePhoneCookie();
      storage.removeIsAgreeTerm();
      storage.removeRoleName();
      setShowModal(true);
      // eslint-disable-next-line
  }, [storage]);

  const submitForm = useCallback(
    async (role: string) => {
      const formValue = methods.getValues();
      const email = storage.getEmailCookie();
      const phone = storage.getPhoneCookie();
      const isAgreeTerm = storage.getIsAgreeTerm();
      const params = {
        ...omit(formValue, 'confirmPassword'),
        email,
        phone,
        role,
        country: formValue.country.label,
        city: formValue.city.label,
        flag: formValue.country.flag,
        countryCode: formValue.country.value,
        latitude: formValue.city.latitude,
        longitude: formValue.city.longitude,
        isAgreeTermsConditions: !!isAgreeTerm,
        birthday: dayjs(formValue.birthday).format('YYYY-MM-DD'),
        majorId: role === ROLES.STUDENT ? undefined : formValue.major,
        school: role === ROLES.STUDENT ? undefined : formValue.school,
        subjects: formValue.subjects
      };
      onSubmit(params);
    },
    // eslint-disable-next-line
    [storage, methods]
  );

  const onCloseModal = useCallback(() => {
    setShowModal(false);
    navigate('login');
  }, []);

  const { asyncCallback: onSubmit } = useAsyncCallback(postSignUp, []);

  const handleProceed = useCallback(async () => {
    const role = methods.getValues('role');
    submitForm(role || ROLES.STUDENT);
    // eslint-disable-next-line
  }, [methods]);

  const handleAgreeTerm = useCallback(() => {
    storage.setIsAgreeTerm('1');
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const handleChooseRole = useCallback(
    async (isStudent?: boolean) => {
      const formValue = methods.getValues();
      const role = !isStudent ? ROLES.BLOOMBASSADOR : ROLES.STUDENT;
      methods.setValue('role', role);
      storage.setFormSignUp({ ...formValue, role: role });
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    },
    [methods]
  );
  const renderIntro = useCallback(
    (step: number) => {
      const birthday = methods.getValues('birthday');
      const isUnder18 = checkAgeIsUnder18(birthday);
      if (step === 0) return <FormPassword isMobile={isMobile} activeStep={step} />;
      if (step === 1) return <FormName isMobile={isMobile} activeStep={step} />;
      if (step === 2) return <FormBirthday isMobile={isMobile} activeStep={step} />;
      if (step === 3) return <FormAddress isMobile={isMobile} activeStep={step} />;
      if (step === 4) return <FormTerm onYes={handleAgreeTerm} isMobile={isMobile} />;
      if (step === 5 && !isUnder18)
        return (
          <FormQuestion
            onYes={() => handleChooseRole()}
            onNo={() => handleChooseRole(true)}
            isMobile={isMobile}
          />
        );
      return (
        <FormInfo
          activeStep={step}
          onCancel={handleBack}
          onProceed={handleProceed}
          isMobile={isMobile}
        />
      );
    },
    // eslint-disable-next-line
    [isMobile, methods]
  );

  const renderBg = useCallback(
    (active: number) => {
      if (active === 0)
        return isMobile ? '/images/bg-landing-mobile-1.png' : '/images/bg-landing-1.png';
      if (active === 1)
        return isMobile ? '/images/bg-landing-mobile-2.png' : '/images/bg-landing-2.png';
      if (active === 2)
        return isMobile ? '/images/bg-landing-mobile-3.png' : '/images/bg-landing-3.png';
      if (active === 3)
        return isMobile ? '/images/bg-landing-mobile-4.png' : '/images/bg-landing-4.png';
      if (active === 4)
        return isMobile ? '/images/bg-landing-mobile-5.png' : '/images/bg-landing-5.png';
      return isMobile ? '/images/bg-landing-mobile-6.png' : '/images/bg-landing-6.png';
    },
    [isMobile]
  );

  const renderUrlStep = useCallback(
    (stepActive: number, indexStep: number, item: TypeSteps) => {
      if (stepActive < indexStep && isMobile)
        return <img src={item.iconMobileInactive} alt={'icon-step'} />;
      if (stepActive < indexStep) return <img src={item.iconActive} alt={'icon-step'} />;
      if (stepActive >= indexStep && isMobile)
        return <img src={item.iconMobileActive} alt={'icon-step'} />;
      return <img src={item.iconInactive} alt={'icon-step'} />;
    },
    [isMobile]
  );

  const renderColor = useCallback((active: number, index: number) => {
    if (active === index) return '#49C2F7';
    if (active > index) return '#76CC36';
    return '#D8D8D8';
  }, []);

  const renderJustifyContent = useCallback(() => {
    if (activeStep < 5 || isMobile) return 'space-between';
    return 'center';
  }, [activeStep, isMobile]);

  const checkDistanceRight = useCallback((index: number) => {
    if (index === 2) return 28;
    if (index === 4) return 32;
    return 0;
  }, []);

  return (
    <Stack>
      <LayoutLanding bg={renderBg(activeStep)}>
        <FormProvider {...methods}>
          <>
            <Stack
              justifyContent={renderJustifyContent()}
              minHeight={'100vh'}
              pb={12.5}
              alignItems={'center'}
              width={'100%'}>
              <Stack
                gap={{ xs: 5, md: 8 }}
                alignItems={'center'}
                width={'100%'}
                px={{ xs: '25px', md: 'unset' }}>
                {activeStep < 5 && (
                  <Stack maxWidth={'936px'} pt={{ xs: 3, md: 10 }} alignItems={'center'}>
                    <WrapperStep
                      activeStep={activeStep}
                      sx={{
                        maxWidth: isMobile ? 'unset' : '936px',
                        width: isMobile ? 'calc(100vw - 76px)' : 'calc(100vw - 476px)'
                      }}>
                      {steps.map((item, index) => (
                        <StepStyled key={index} sx={{ position: 'relative' }}>
                          <StepLabel></StepLabel>
                          <Stack
                            position={'absolute'}
                            top={isMobile ? -14 : -45}
                            right={isMobile ? -29 : -50}
                            mr={1.25}>
                            <Stack>{renderUrlStep(activeStep, index, item)}</Stack>
                            {!isMobile && (
                              <Typography
                                top={'80px'}
                                right={checkDistanceRight(index)}
                                textAlign={'center'}
                                color={renderColor(activeStep, index)}
                                fontWeight={700}
                                style={{
                                  width: '112px',
                                  position: 'absolute',
                                  whiteSpace: 'nowrap'
                                }}>
                                {item.label}
                              </Typography>
                            )}
                          </Stack>
                        </StepStyled>
                      ))}
                    </WrapperStep>
                  </Stack>
                )}
                {renderIntro(activeStep)}
              </Stack>
              {activeStep < 4 ? (
                <Stack
                  flexDirection={'row'}
                  justifyContent={isMobile ? 'center' : 'space-between'}
                  width={{ xs: 'calc(100% - 50px)', md: 'calc(100% - 200px)' }}
                  maxWidth={'1226px'}
                  gap={isMobile ? '38px' : 'unset'}>
                  <Stack sx={{ width: isMobile ? '100%' : 170 }}>
                    {activeStep !== 0 && (
                      <PrimaryButton
                        isOutline
                        label={'Back'}
                        style={{ width: isMobile ? '100%' : 170 }}
                        onClick={handleBack}
                        iconLeft={<img alt={'button-img'} src={'icons/icon_back.svg'} />}
                      />
                    )}
                  </Stack>
                  <PrimaryButton
                    onClick={methods.handleSubmit(handleNext)}
                    label={'Continue'}
                    style={{ width: isMobile ? '100%' : 170 }}
                    icon={<img alt={'button-img'} src={'icons/icon_next.svg'} />}
                  />
                </Stack>
              ) : (
                <Stack />
              )}
            </Stack>
            <DialogConfirm
              isOpen={showModal}
              textButton={'Got It'}
              textTitle={'Account Registration Successful'}
              textContent={
                "Thank you for registering your email. Please check your Gmail inbox for a confirmation message and follow the instructions to complete the process. If you don't see the email, kindly check your Spam or Junk folder."
              }
              onSubmit={onCloseModal}
              onClose={onCloseModal}
            />
          </>
        </FormProvider>
      </LayoutLanding>
    </Stack>
  );
};

const StepStyled = styled(Step)(() => ({
  '& .MuiStepLabel-iconContainer': {
    padding: 0
  },
  '& .MuiSvgIcon-fontSizeMedium': {
    width: 0
  }
}));

const WrapperStep = styled(Stepper)(() => ({
  '& .Mui-active': {
    '& .MuiStepConnector-line': {
      background: '#49C2F7',
      height: '2px'
    }
  },
  '& .Mui-completed': {
    '& .MuiStepConnector-line': {
      background: '#76CC36',
      height: '2px'
    }
  },
  '& .Mui-disabled': {
    '& .MuiStepConnector-line': {
      background: '#DCDCDC',
      height: '2px'
    }
  }
}));
