export const IconPinMaker = () => {
  return (
    <svg width="30" height="35" viewBox="0 0 19 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.5 0C6.98132 0.00285178 4.56662 1.00274 2.78564 2.78029C1.00466 4.55785 0.00285727 6.96791 0 9.48175C0 17.5951 8.63636 23.7227 9.00449 23.9791C9.1497 24.0807 9.3227 24.1351 9.5 24.1351C9.6773 24.1351 9.8503 24.0807 9.99551 23.9791C10.3636 23.7227 19 17.5951 19 9.48175C18.9971 6.96791 17.9953 4.55785 16.2144 2.78029C14.4334 1.00274 12.0187 0.00285178 9.5 0ZM9.5 6.03384C10.1832 6.03384 10.8511 6.23606 11.4192 6.61492C11.9873 6.99378 12.4301 7.53227 12.6916 8.1623C12.9531 8.79232 13.0215 9.48558 12.8882 10.1544C12.7549 10.8232 12.4259 11.4376 11.9427 11.9198C11.4596 12.402 10.8441 12.7304 10.1739 12.8634C9.50383 12.9965 8.80924 12.9282 8.178 12.6672C7.54677 12.4062 7.00724 11.9643 6.62765 11.3973C6.24806 10.8303 6.04545 10.1637 6.04545 9.48175C6.04545 8.56731 6.40941 7.69032 7.05727 7.04371C7.70512 6.3971 8.5838 6.03384 9.5 6.03384Z"
        fill="#fff"
      />
    </svg>
  );
};
