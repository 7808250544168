import { Stack, useMediaQuery } from '@mui/material';
import { Logo } from 'app/components/elements/Logo';
import { LayoutLanding } from 'app/components/templates/LayoutLanding';
import { FormLogin, FormLoginType } from 'app/pages/LoginPage/components/FormLogin';
import axios from 'axios';
import { useFlash } from 'contexts/flash';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { StorageServices } from 'services/storage';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
const storage = new StorageServices();

export const LoginPage = () => {
  const { setFlash } = useFlash();
  const isMobile = useMediaQuery('(max-width:900px)');
  const navigate = useNavigate();
  const postLogin = useCallback(async (data: FormLoginType) => {
    const res = await axios.post('/auth/signin', data);
    setFlash({ type: 'success', message: 'You are successfully logged in' });
    storage.setAccessToken(res.data.accessToken);
    storage.setRefreshToken(res.data.refreshToken);
    navigate('/');
    // eslint-disable-next-line
  }, []);

  const { asyncCallback: handleSubmitForm } = useAsyncCallback(postLogin, []);

  return (
    <Stack>
      <LayoutLanding bg={isMobile ? '/images/bg-landing-mobile-1.png' : '/images/bg-landing-1.png'}>
        <Stack mt={2.5} mb={3.5} width={'100%'} alignItems={'center'} minHeight={'100vh'}>
          <Logo />
          <Stack
            px={{ xs: 3.125, sm: 0 }}
            mt={3}
            width={'100%'}
            maxWidth={{ xs: 'unset', sm: 402 }}>
            <FormLogin onSubmit={handleSubmitForm} />
          </Stack>
        </Stack>
      </LayoutLanding>
    </Stack>
  );
};
