import { Grid, Stack, styled, Typography, useMediaQuery } from '@mui/material';
import { TruncateTableText } from 'app/components/elements/TruncateTableText';
import { ROLES } from 'app/pages/Introduction';
import axios from 'axios';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { usePaging } from 'hooks/usePaging';
import { CustomPagination } from 'app/components/elements/Pagination';
import { PrimaryButton } from 'app/components/elements/ButtonCustom';
import { User } from 'types/User';
import { ImageUser } from 'app/components/elements/ImageUser';
import { IconFlowerCustom } from 'app/components/elements/icons/IconFlowerCutom';
import { renderColorSubject } from 'utils/renderColorSubject';
import { Answer, Questions } from 'types/questions';
import { RecordUserCard } from 'app/components/elements/RecordUserCard';
import get from 'lodash/get';

export const UserProfilePage = () => {
  const navigate = useNavigate();
  const isTablet = useMediaQuery('(max-width:900px)');
  // const { setFlash } = useFlash();
  const [searchParams, setSearchParams] = useSearchParams();
  const [user, setUser] = useState<User | null>(null);
  const { id } = useParams();
  const [listResponses, setListResponses] = useState<Questions[] | Answer[]>([]);
  const renderRowPage = useMemo(() => {
    if (isTablet) return 9;
    return 8;
  }, [isTablet]);

  const { pagingInfo, setCurrentPage, setTotal } = usePaging({
    rowsPerPage: renderRowPage
  });

  const handleChangePage = useCallback((value: number) => {
    setCurrentPage(value);
    setSearchParams({ tab: 'responses', page: value.toString() });
    // eslint-disable-next-line
  }, []);

  const fetchUserProfile = useCallback(async () => {
    const { data } = await axios.get(`/users/${id}`);
    if (data.success) {
      setUser(data.user);
    }
  }, []);

  const fetchVideos = useCallback(
    async (page: string) => {
      const endpoint = user?.role === 'STUDENT' ? 'questions' : 'answers';
      const res = await axios.get(
        `/${endpoint}/users/${id}?page=${page || 1}&size=${renderRowPage}`
      );
      setListResponses(res.data.result);
      setCurrentPage(Number(page));
      setTotal(res.data.total);
      // eslint-disable-next-line
    }, [id, user]);

  const { asyncCallback: fetchData } = useAsyncCallback(fetchVideos, []);

  useEffect(() => {
    if (!id) return;
    fetchUserProfile();
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (!user) return;
    fetchData(searchParams.get('page') || 1);
    // eslint-disable-next-line
  }, [user]);

  return (
    <Stack py={13.25} px={5} width={'100%'}>
      <Stack flexDirection={'row'} height={'100%'} alignItems={'center'} gap={2.75} flex={1}>
        <Stack
          maxWidth={'109px'}
          width={'100%'}
          height={'109px'}
          alignItems={'center'}
          justifyContent={'center'}>
          <ImageUser isCircle url={user?.avatar} subjects={user?.subjects || []} />
        </Stack>
        <Stack gap={0.75}>
          <TruncateTableText
            isPadding={false}
            textProps={{ fontSize: '24px', fontWeight: 700 }}
            colorText={'#770BA1'}
            text={user?.name || ''}
          />
          {user?.major && (
            <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
              <IconFlowerCustom subjectId={user.major.id} />
              <Typography
                fontSize={'18px'}
                fontWeight={400}
                whiteSpace={'pre-line'}
                color={renderColorSubject(Number(user.major.id))?.color || '#0000'}>
                {renderColorSubject(Number(user.major.id))?.label}
              </Typography>
            </Stack>
          )}
          <Stack flexDirection={'row'} flexWrap={'wrap'}>
            {user?.subjects.map((subject, index: number) => (
              <Stack key={subject.id} flexDirection={'row'} alignItems={'center'} gap={1}>
                {index === 0 && <TextTitle>Interests:</TextTitle>}
                <IconFlowerCustom subjectId={subject?.id} />
                <Typography
                  fontSize={'18px'}
                  fontWeight={400}
                  whiteSpace={'pre-line'}
                  color={renderColorSubject(Number(subject?.id))?.color || '#0000'}>
                  {renderColorSubject(Number(subject?.id))?.label}
                </Typography>
                {user.subjects.length !== index + 1 && (
                  <Typography fontSize={'20px'} color={'#686868'} mr={0.5}>
                    ,
                  </Typography>
                )}
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Stack>
      <Stack
        mt={2.5}
        mb={3}
        width={'178px'}
        height={31}
        alignItems={'center'}
        justifyContent={'center'}
        borderRadius={'20px'}
        sx={{ background: '#D090D7' }}>
        <Typography color={'#fff'} fontSize={'14px'} fontWeight={700}>
          {user?.role === ROLES.STUDENT ? 'Videos' : 'Responses'}
        </Typography>
      </Stack>
      <Grid container spacing={2}>
        {listResponses?.map((item, index: number) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <RecordUserCard
              createAt={item.createdAt}
              questionsId={item.id}
              comments={item.comments}
              isQuestions={user?.role === ROLES.STUDENT}
              url={item.record.url}
              title={item.title}
              subjectId={get(item, 'subject.id') || get(item, 'question.subjectId')}
            />
          </Grid>
        ))}
      </Grid>
      <Stack
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent={'space-between'}
        alignItems={'center'}
        gap={{ xs: 2, md: 'unset' }}>
        <Stack width={'170px'} />
        {pagingInfo.pageCount !== 0 && (
          <CustomPagination
            page={pagingInfo.currentPage}
            count={pagingInfo.pageCount}
            onChangePage={handleChangePage}
          />
        )}
        <PrimaryButton
          isOutline
          label={'Back'}
          style={{ width: 170, height: 61 }}
          onClick={() => navigate(-1)}
          iconLeft={<img alt={'button-img'} src={'/icons/icon_back.svg'} />}
        />
      </Stack>
    </Stack>
  );
};

const TextTitle = styled(Typography)(() => ({
  fontWeight: 400,
  color: '#770BA1',
  fontSize: '18px',
  lineHeight: 'normal'
}));
