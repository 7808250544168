import { Stack, styled, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { PrimaryButton } from 'app/components/elements/ButtonCustom';
import { useState } from 'react';

type NoteBlogType = {
  initValue?: string;
  onNote: (value: string) => void;
  isLoadingNote?: boolean;
};
export const NoteBlog = ({ initValue, onNote, isLoadingNote }: NoteBlogType) => {
  const [note, setNote] = useState<string>(initValue || '');
  const line = window.innerWidth < 600 ? 15 : 25;
  const maxRow = window.innerWidth < 600 ? 8 : 20;
  return (
    <WrapperNoteBlog position={'relative'}>
      <Stack
        gap={3.125}
        position={'absolute'}
        top={window.innerWidth < 600 ? 10 : 20}
        left={0}
        width={'100%'}>
        {[...Array(line)].map((_, index) => (
          <Stack key={index} width={'100%'} height={'1px'} sx={{ background: '#A1E5D4' }} />
        ))}
      </Stack>
      <Stack style={{ zIndex: 9999 }}>
        <Typography fontSize={'24px'} fontWeight={700} color={'#4D44B5'}>
          Notes
        </Typography>
        <TextContent
          value={note}
          label="Note here"
          multiline
          maxRows={maxRow}
          variant="filled"
          onChange={(e) => {
            setNote(e.target.value);
          }}
        />
      </Stack>
      <WrapperButton>
        <PrimaryButton
          onClick={() => onNote(note)}
          label={'ADD'}
          fontSizeText={'20px'}
          sx={{ borderRadius: '12px' }}
          isIconFlower
          isLoading={isLoadingNote}
        />
      </WrapperButton>
    </WrapperNoteBlog>
  );
};
const WrapperButton = styled(Stack)(() => ({
  minWidth: 280
}));
const WrapperNoteBlog = styled(Stack)(() => ({
  border: '4px solid #697D21',
  borderRadius: '20px',
  background: '#FFED8F',
  width: '100%',
  height: '100%',
  padding: '18px 20px',
  justifyContent: 'space-between'
}));
const TextContent = styled(TextField)(() => ({
  background: 'none !important',
  color: '#686868 !important',
  '.MuiFilledInput-root': {
    color: '#686868 !important',
    lineHeight: '26px !important',
    background: 'none !important',
    fontSize: '18px'
  },
  '& label': {
    color: '#686868 !important',
    fontSize: '18px'
  }
}));
