import { Stack } from '@mui/material';
import { LayoutRole } from 'app/components/templates/LayoutRole';
import { AdminCommentTable } from 'app/pages/AdminCommentsPage/components/AdminCommentTable';
import { Header } from 'app/pages/AdminQuestionsPage/components/AdminQuestionsTable';
import { ROLES } from 'app/pages/Introduction';
import axios from 'axios';
import { SIZE_ADMIN_QUESTIONS } from 'constants/paging';
import { useFlash } from 'contexts/flash';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { usePaging } from 'hooks/usePaging';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Comment } from 'types/comment';

const HEADER_ANSWERS: Header[] = [
  {
    label: 'Name',
    width: '12%'
  },
  {
    label: 'Questions'
  },
  {
    label: 'Attachment'
  },
  {
    label: 'Comment'
  },
  {
    label: 'Action',
    width: '200px'
  }
];
export const AdminCommentsPage = () => {
  const { setFlash } = useFlash();
  const [searchParams, setSearchParams] = useSearchParams();
  const [listComments, setListComments] = useState<Comment[]>([]);
  const { pagingInfo, setCurrentPage, setTotal } = usePaging({
    rowsPerPage: SIZE_ADMIN_QUESTIONS
  });
  const handleChangePage = useCallback((value: number) => {
    setCurrentPage(value);
    setSearchParams({ page: value.toString() });
    // eslint-disable-next-line
  }, []);

  const fetchDataListUser = useCallback(async (page: string) => {
    const res = await axios.get(`/admin/comments?page=${page || 1}&size=${SIZE_ADMIN_QUESTIONS}`);
    setListComments(res.data.comments as Comment[]);
    setCurrentPage(Number(page));
    setTotal(res.data.total);
    // eslint-disable-next-line
  }, []);

  const { asyncCallback: handleFetchData } = useAsyncCallback(fetchDataListUser, []);
  useEffect(() => {
    handleFetchData(searchParams.get('page') || 1);
    // eslint-disable-next-line
  }, [searchParams.get('page')]);

  const handleOnReject = useCallback(
    async (id: string) => {
      const { data: res } = await axios.put(`/admin/comments/${id}/approve`, { isApprove: false });
      if (!res.result) return;
      const newList = listComments.filter((item) => item.id !== id);
      setListComments(newList);
      setFlash({ type: 'success', message: 'Delete Comment Video Successfully' });
      if (newList.length === 0) {
        const page = searchParams.get('page');
        const newPage = Number(page) - 1;
        setSearchParams({ page: newPage.toString() });
      }
    },
    [listComments, searchParams.get('page')]
  );

  const { asyncCallback: asyncHandleReject } = useAsyncCallback(handleOnReject, []);
  return (
    <LayoutRole roles={[ROLES.ADMIN]}>
      <Stack width={'100%'}>
        <AdminCommentTable
          headers={HEADER_ANSWERS}
          listComments={listComments}
          onReject={asyncHandleReject}
          pagination={pagingInfo}
          handleChangePage={handleChangePage}
        />
      </Stack>
    </LayoutRole>
  );
};
