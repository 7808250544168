import { Grid, Stack } from '@mui/material';
import { CustomPagination } from 'app/components/elements/Pagination';
import { RecordUserCard } from 'app/components/elements/RecordUserCard';
import axios from 'axios';
import { SIZE_PAGE_UPLOAD_VIDEO } from 'constants/paging';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { usePaging } from 'hooks/usePaging';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Questions } from 'types/questions';

type UploadVideoTabProps = {
  userId?: string;
};
export const UploadVideoTab = ({ userId }: UploadVideoTabProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [listResponses, setListResponses] = useState<Questions[]>([]);

  const { pagingInfo, setCurrentPage, setTotal } = usePaging({
    rowsPerPage: SIZE_PAGE_UPLOAD_VIDEO
  });

  const handleChangePage = useCallback((value: number) => {
    setCurrentPage(value);
    setSearchParams({ tab: 'video-upload', page: value.toString() });
    // eslint-disable-next-line
  }, []);

  const fetchDataListUser = useCallback(
    async (page: string) => {
      if (!userId) return;
      const res = await axios.get(
        `/questions/users/${userId}?page=${page || 1}&size=${SIZE_PAGE_UPLOAD_VIDEO}`
      );
      setListResponses(res.data.result as Questions[]);
      setCurrentPage(Number(page));
      setTotal(res.data.total);
      // eslint-disable-next-line
    }, [userId]);
  const { asyncCallback: handleFetchData } = useAsyncCallback(fetchDataListUser, []);
  useEffect(() => {
    handleFetchData(searchParams.get('page') || 1);
    // eslint-disable-next-line
  }, [searchParams.get('page')]);

  return (
    <Stack flexDirection={'row'} flexWrap={'wrap'} gap={2}>
      <Grid container spacing={1} style={{ justifyContent: 'flex-start' }}>
        {listResponses?.map((item, index: number) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <RecordUserCard
              createAt={item.createdAt}
              questionsId={item.id}
              comments={item.comments}
              isQuestions
              url={item.record.url}
              title={item.title}
              subjectId={item.subject.id}
            />
          </Grid>
        ))}
      </Grid>
      <Stack
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent={'center'}
        width={'100%'}
        alignItems={'center'}
        gap={{ xs: 2, md: 'unset' }}>
        {pagingInfo.pageCount !== 0 && (
          <CustomPagination
            page={pagingInfo.currentPage}
            count={pagingInfo.pageCount}
            onChangePage={handleChangePage}
          />
        )}
      </Stack>
    </Stack>
  );
};
