import {
  Box,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { ButtonNoBoxShadow } from 'app/components/elements/ButtonCustom';
import { DialogPreviewRecord } from 'app/components/elements/DialogPreviewRecord';
import { CustomPagination } from 'app/components/elements/Pagination';
import { TextSubjectId } from 'app/components/elements/TextSubjectId';
import { useState } from 'react';
import { StyledParams } from 'types/common';
import { PagingInfo } from 'types/paging';
import { Questions } from 'types/questions';

export type Header = {
  label: string;
  width?: string;
};
type AdminQuestionsTableProps = {
  handleChangePage: (value: number) => void;
  pagination: PagingInfo;
  listQuestions: Questions[];
  onApprove: (id: string) => void;
  onReject: (id: string) => void;
  headers: Header[];
};
export const AdminQuestionsTable = ({
  handleChangePage,
  listQuestions,
  pagination,
  onApprove,
  onReject,
  headers
}: AdminQuestionsTableProps) => {
  const [questionsPreview, setQuestionsPreview] = useState<Questions | null>(null);
  return (
    <Box overflow={'auto'} width={'100%'} p={{ xs: '20px', md: '100px 50px' }}>
      <TableWrapper overflow={'auto'}>
        <TableListAdminCompany sx={{ overflow: 'auto' }}>
          <TableHead>
            <TableRow sx={{ height: 60 }}>
              {headers.map((header, index: number) => (
                <TableCellHeadStyled key={index} sx={{ width: header.width || 'unset' }}>
                  {header.label}
                </TableCellHeadStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {listQuestions &&
              listQuestions.map((questions, index: number) => {
                return (
                  <TableRowStyled key={index}>
                    <TableCellStyled>
                      <TextWrapper>{questions.user.name}</TextWrapper>
                    </TableCellStyled>
                    <TableCellStyled>
                      <TextWrapper>{questions.user.email}</TextWrapper>
                    </TableCellStyled>
                    <TableCellStyled sx={{ padding: '8px 0' }}>
                      <Stack
                        height={'50%'}
                        alignItems={'flex-start'}
                        borderBottom={'1px solid #E3E2E6'}
                        justifyContent={'center'}
                        pb={1}>
                        <TextWrapper>{`Title: ${questions.title}`}</TextWrapper>
                      </Stack>
                      <Stack height={'50%'} alignItems={'flex-start'} justifyContent={'center'}>
                        <Stack flexDirection={'row'} alignItems={'center'} pt={1}>
                          <TextWrapper>Major: </TextWrapper>
                          <TextSubjectId subjectId={questions?.subject?.id} />
                        </Stack>
                      </Stack>
                    </TableCellStyled>
                    <TableCellStyled>
                      <Stack p={1.25} gap={1}>
                        <ButtonNoBoxShadow
                          onClick={() => {
                            setQuestionsPreview(questions);
                          }}
                          label={'View Video'}
                          style={{ width: 170, height: 30, background: '#D090D7' }}
                        />
                        <ButtonNoBoxShadow
                          onClick={() => onReject(questions.id)}
                          label={'Delete'}
                          style={{ width: 170, height: 30, background: '#f22369' }}
                        />
                        {!questions.isApproved && (
                          <ButtonNoBoxShadow
                            onClick={() => onApprove(questions.id)}
                            label={'Accept'}
                            style={{ width: 170, height: 30, background: '#81b734' }}
                          />
                        )}
                      </Stack>
                    </TableCellStyled>
                  </TableRowStyled>
                );
              })}
          </TableBody>
        </TableListAdminCompany>
      </TableWrapper>
      <Stack
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent={'center'}
        width={'100%'}
        alignItems={'center'}
        gap={{ xs: 2, md: 'unset' }}
        pt={2.5}>
        {pagination.pageCount !== 0 && (
          <CustomPagination
            page={pagination.currentPage}
            count={pagination.pageCount}
            onChangePage={handleChangePage}
          />
        )}
      </Stack>
      {questionsPreview && (
        <DialogPreviewRecord
          isOpen={!!questionsPreview}
          onClose={() => setQuestionsPreview(null)}
          url={questionsPreview.record.url}
          subjectId={questionsPreview?.subject?.id || ''}
        />
      )}
    </Box>
  );
};

const TableWrapper = styled(Box)(({ theme }: StyledParams) => ({
  minHeight: 'calc(100vh - 580px)',

  [theme.breakpoints.down('lg')]: {
    maxWidth: `calc(100vw - 255px)`
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: `calc(100vw - 32px)`
  }
}));

const TableListAdminCompany = styled(Table)(({ theme }: StyledParams) => ({
  [theme.breakpoints.down('lg')]: {
    minWidth: 'max-content'
  },
  [theme.breakpoints.down('md')]: {
    minWidth: 'unset'
  }
}));

const TableCellStyled = styled(TableCell)(({ theme }: StyledParams) => ({
  border: `1px solid ${theme.colors.border.base}`,
  padding: theme.spacing(0),
  fontSize: 16,
  maxWidth: '330px',
  lineHeight: '30px',
  color: theme.colors.text.lightness1,
  height: 60
}));

const TableRowStyled = styled(TableRow)(() => ({
  height: 60,
  '&:hover': {
    background: `linear-gradient(180deg, #FAFDFF 0%, #EEF7FF 9.31%, #D4EAFD 59.58%)`,
    cursor: 'pointer'
  }
}));

const TextWrapper = styled(Typography)(({ theme }: StyledParams) => ({
  padding: theme.spacing(0, 2),
  color: '#000'
}));

const TableCellHeadStyled = styled(TableCell)(({ theme }: StyledParams) => ({
  border: `1px solid ${theme.colors.border.base}`,
  textAlign: 'center',
  fontWeight: 700,
  fontSize: 16,
  lineHeight: '17px',
  color: '#770BA1',
  padding: theme.spacing(0, 0),
  backgroundColor: '#D090D7'
}));
