import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { getDataError, getErrorMsg } from 'utils/getErrorMsg';
import { useFlash } from 'contexts/flash';
import { useLoading } from 'contexts/loading';

/**
 * @returns function handle errors
 */
export function useAsyncCallback(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cb: any,
  deps: unknown[],
  isThrow?: boolean,
  isHiddenToast?: boolean
) {
  const { setFlash } = useFlash();
  const { setLoading } = useLoading();
  const [errorReturnApi, setErrorReturnApi] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [dataError, setDataError] = useState<any>({});
  const asyncCallback = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (...args: any) => {
      setLoading(true);
      setIsLoading(true);
      return cb(...args)
        .catch((error: unknown) => {
          if (isThrow) throw error;
          if (error instanceof AxiosError) {
            const messageError = getErrorMsg(error);
            const dataErrorRes = getDataError(error);
            setDataError(dataErrorRes);
            setErrorReturnApi(messageError);
            !isHiddenToast &&
              setFlash({
                message: messageError,
                type: 'error'
              });
          }
          setLoading(false);
          setIsLoading(false);
        })
        .finally(() => {
          setLoading(false);
          setIsLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...deps, cb]
  );
  return {
    asyncCallback,
    errorReturnApi,
    setErrorReturnApi,
    isLoading,
    dataError,
    setDataError
  };
}
