import { motion } from 'framer-motion';
import { Stack, styled, useMediaQuery } from '@mui/material';

const flowerTypes = [
  '/images/animation_flower1.png',
  '/images/animation_flower2.png',
  '/images/animation_flower3.png',
  '/images/animation_flower4.png',
  '/images/animation_flower5.png'
];
const flowerTypesMobile = [
  '/images/animation_flower1.png',
  '/images/animation_flower4.png',
  '/images/animation_flower5.png'
];

export const ExplodingAnimationFlower = () => {
  const isMobile = useMediaQuery('(max-width:700px)');

  const generateRandomNumber = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
  };

  const flowers = [];

  const horizontalSpacing = 120;
  const verticalSpacing = 150;
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;

  const numCols = Math.ceil(screenWidth / horizontalSpacing) + 6;
  const numRows = Math.ceil(screenHeight / verticalSpacing) + 6;

  const offsetX = -(numCols * horizontalSpacing - screenWidth) / 2;
  const offsetY = -(numRows * verticalSpacing - screenHeight) / 2;

  let flowerIndex = 0;

  for (let row = 0; row < numRows; row++) {
    for (let col = 0; col < numCols; col++) {
      flowers.push(
        <motion.div
          key={`${row}-${col}`}
          style={{
            position: 'absolute',
            top: `${row * verticalSpacing + offsetY}px`,
            left: `${col * horizontalSpacing + offsetX}px`,
            zIndex: 100
          }}
          animate={{
            y: [-200, 0],
            opacity: [0, 1],
            scale: [0, generateRandomNumber(0.5, 1.5), 1],
            rotate: generateRandomNumber(0, 360)
          }}
          transition={{
            duration: generateRandomNumber(0.5, 2),
            ease: 'easeInOut'
          }}>
          {isMobile ? (
            <img src={flowerTypesMobile[flowerIndex]} alt={`flower-${flowerIndex}`} />
          ) : (
            <img src={flowerTypes[flowerIndex]} alt={`flower-${flowerIndex}`} />
          )}
        </motion.div>
      );
      if (isMobile) {
        flowerIndex = (flowerIndex + 1) % flowerTypesMobile.length;
      } else {
        flowerIndex = (flowerIndex + 1) % flowerTypes.length;
      }
    }
  }

  const ContainerStyle = styled(Stack)(() => ({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    overflow: 'hidden'
  }));

  return <ContainerStyle>{flowers}</ContainerStyle>;
};
