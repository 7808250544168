export const IconFlowerCustomMap = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" viewBox="0 0 93 87" fill="none">
      <ellipse
        cx="53.3526"
        cy="32.614"
        rx="13.3267"
        ry="15.0377"
        transform="rotate(31.077 53.3526 32.614)"
        fill="#FF006B"
      />
      <ellipse
        cx="13.4111"
        cy="14.9448"
        rx="13.4111"
        ry="14.9448"
        transform="matrix(0.324628 0.945842 -0.940814 0.338924 74.7593 27.5674)"
        fill="#FF006B"
      />
      <ellipse
        cx="13.4686"
        cy="14.8807"
        rx="13.4686"
        ry="14.8807"
        transform="matrix(0.905708 -0.423902 0.410852 0.911702 14.8821 23.4551)"
        fill="#FF006B"
      />
      <ellipse
        cx="13.354"
        cy="15.0077"
        rx="13.354"
        ry="15.0077"
        transform="matrix(0.986161 0.16579 -0.176057 0.98438 44.3784 38.2607)"
        fill="#FF006B"
      />
      <ellipse
        cx="13.354"
        cy="15.0077"
        rx="13.354"
        ry="15.0077"
        transform="matrix(0.986161 0.16579 -0.176057 0.98438 27.7166 32.1211)"
        fill="#FF006B"
      />
      <ellipse
        cx="47.0589"
        cy="39.5504"
        rx="14.4783"
        ry="9.18971"
        transform="rotate(31.077 47.0589 39.5504)"
        fill="#FFF"
      />
    </svg>
  );
};
