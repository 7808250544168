import { Stack } from '@mui/material';
import { SelectOptions } from 'app/components/elements/InputSelect';
import { LayoutRole } from 'app/components/templates/LayoutRole';
import { ROLES } from 'app/pages/Introduction';
import { useFlash } from 'contexts/flash';
import { useLoading } from 'contexts/loading';
import { useAuth } from 'contexts/user';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { NewRecordForm } from 'app/pages/RecordPage/components/RecordForm';
import { RecordVideo } from 'app/components/elements/RecordVideo';
import { PrimaryButton } from 'app/components/elements/ButtonCustom';
import { RecordInfo } from 'app/pages/RecordPage/components/RecordInfo';
import { FormProvider, useForm } from 'react-hook-form';
import { InfoQuestions, StorageServices } from 'services/storage';
import { User } from 'types/User';
import { ExplodingAnimationFlower } from 'utils/animation';
import { DialogConfirm } from 'app/components/elements/DialogConfirm';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import dayjs from 'dayjs';
import { saveVideoFile } from 'utils/saveFile';
const storage = new StorageServices();
export type FormRecord = {
  video?: string;
  subject: string;
  title: string;
  questions: string;
  isQuestions: boolean;
  lat: number;
  lng: number;
  user?: SelectOptions;
  isTutorial?: boolean;
  fileUpload?: File;
  recordType?: string;
};
const listVideoUploadRes = storage.getInfoQuestionsCookie();

export const RecordPage = () => {
  const { setFlash } = useFlash();
  const { loading } = useLoading();
  const methods = useForm<FormRecord>({ defaultValues: { subject: '1' } });
  const { user } = useAuth();
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(1);
  const [recordVideo, setRecordVideo] = useState<string>();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [listUser, setListUser] = useState<SelectOptions[]>([]);
  const [isCheckError, setIsCheckError] = useState<boolean>(false);
  const [errorFileUpload, setErrorFileUpload] = useState<string>('');
  const [listVideoUpload, setListVideoUpload] = useState<InfoQuestions[]>([]);
  const [fileName, setFileName] = useState('');
  useEffect(() => {
    setListVideoUpload(listVideoUploadRes);
  }, [listVideoUploadRes]);
  const [modalSaveFile, setModalSaveFile] = useState<boolean>(false);
  const [fileBlob, setFileBlob] = useState<Blob>();
  const isAdmin = useMemo(() => {
    return user?.role === ROLES.ADMIN;
  }, [user]);

  const isTutorial = methods.watch('isTutorial');
  const lat = methods.watch('lat');
  const lng = methods.watch('lng');

  useEffect(() => {
    if (!recordVideo) setStep(1);
  }, [recordVideo]);

  const handleGetUser = useCallback(async () => {
    const { data: res } = await axios.get('/admin/all-users');
    const dataConvert = (res.users as User[])
      .filter((item) => item.role !== ROLES.ADMIN)
      .map((item) => ({
        value: item.id,
        label: `${item.name} <${item.email}>`
      }));
    setListUser(dataConvert);
  }, []);

  useEffect(() => {
    if (user?.role !== ROLES.ADMIN) return;
    handleGetUser();
  }, [user]);

  const getLongLat = useCallback(async () => {
    try {
      if (!navigator.geolocation) {
        setFlash({ message: 'Geolocation is not supported by your browser', type: 'error' });
        return;
      }
      const mediaDevicesPermission = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'user'
        },
        audio: true
      });
      if (!mediaDevicesPermission) {
        setFlash({ message: 'Camera or microphone permission not grantedranted', type: 'error' });
        return;
      }
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          methods.setValue('lng', longitude);
          methods.setValue('lat', latitude);
        },
        () => {
          setFlash({ message: 'Geolocation permission not granted', type: 'error' });
        }
      );
    } catch (error) {
      setFlash({ message: 'Geolocation permission not granted', type: 'error' });
    }
  }, []);

  useEffect(() => {
    if (lat && lng) return;
    getLongLat();
  }, []);

  const handleRecordVideo = useCallback((video?: string) => {
    setRecordVideo(video);
  }, []);

  const handleNexInfo = useCallback(
    async (value: FormRecord) => {
      const fileUpload = methods.watch('fileUpload');
      if (!fileUpload) {
        setErrorFileUpload('Required Field');
        return;
      }
      const formData = new FormData();
      formData.append('file', fileUpload);
      const resRecord = await axios.post('/storages/questions', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      if (!resRecord.data) return;
      let payload;

      if (!isAdmin) {
        payload = {
          title: value.title,
          subjectId: value.subject,
          recordId: resRecord.data?.record?.id ? Number(resRecord.data.record.id) : undefined,
          longitude: value.lng.toString(),
          latitude: value.lat.toString(),
          userId: user?.role === ROLES.ADMIN ? value.user?.value : undefined
        };
      } else {
        payload = {
          title: value.title,
          subjectId: value.subject,
          recordId: resRecord.data?.record?.id ? Number(resRecord.data.record.id) : undefined,
          isTutorial: value.isTutorial,
          userId: user?.role === ROLES.ADMIN ? value.user?.value : undefined
        };
      }
      await axios.post('/questions', payload);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        setIsOpenModal(true);
      }, 2500);
    },
    [methods, user?.role]
  );
  const handleUploadVideoWithoutInternet = useCallback(
    async (item: InfoQuestions & { file?: File }) => {
      if (!lat || !lng) {
        setFlash({ message: 'Geolocation permission not granted', type: 'error' });
        return;
      }
      if (!item.file) return;
      const formData = new FormData();
      formData.append('file', item.file);
      const resRecord = await axios.post('/storages/questions', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      const payload = {
        title: item.title,
        subjectId: item.subjectId,
        lat,
        lng,
        recordId: resRecord.data?.record?.id ? Number(resRecord.data.record.id) : undefined,
        userId: item.userId
      };

      const newListVideoCookie = listVideoUpload?.filter((video) => video.id !== item.id);
      setListVideoUpload(newListVideoCookie);
      storage.setInfoQuestionsCookie(newListVideoCookie);
      await axios.post('/questions', payload);
    },
    [lat, lng, listVideoUpload, setFlash]
  );

  const deleteVideoUpload = useCallback(
    (item: InfoQuestions & { file?: File }) => {
      const newListVideoCookie = listVideoUpload?.filter((video) => video.id !== item.id);
      setListVideoUpload(newListVideoCookie);
      storage.setInfoQuestionsCookie(newListVideoCookie);
    },
    [listVideoUpload]
  );

  const {
    asyncCallback: asyncHandleUploadVideoWithoutInternet,
    isLoading: LoadingSubmitUploadVideoWithoutInternet
  } = useAsyncCallback(handleUploadVideoWithoutInternet, []);

  const { asyncCallback: asyncHandleNexInfo } = useAsyncCallback(handleNexInfo, []);

  const handleStartRecord = useCallback(() => {
    setStep((prevState) => prevState + 1);
  }, []);
  const handleCancel = useCallback(() => {
    setStep(1);
    setRecordVideo('');
  }, []);
  const renderStep = useCallback(() => {
    if (step === 1)
      return (
        <NewRecordForm
          listUser={listUser}
          setErrorFileUpload={setErrorFileUpload}
          isAdmin={isAdmin}
          handleNexInfo={asyncHandleNexInfo}
          onNewRecord={handleStartRecord}
          errorFileUpload={errorFileUpload}
          handleUploadVideo={asyncHandleUploadVideoWithoutInternet}
          LoadingSubmitUploadVideoWithoutInternet={LoadingSubmitUploadVideoWithoutInternet}
          listVideoUpload={listVideoUpload}
          deleteVideoUpload={deleteVideoUpload}
        />
      );
    if (step === 2)
      return (
        <RecordVideo
          isTutorial={isTutorial}
          handleCancel={handleCancel}
          onSubmit={handleRecordVideo}
          subjectId={methods.getValues('subject')}
        />
      );
    if (step === 3)
      return (
        <RecordInfo
          isTutorial={isTutorial}
          step={step}
          videoUrl={recordVideo}
          listUser={listUser}
          userRole={user?.role}
          isCheckError={isCheckError}
        />
      );
    return;
  }, [
    step,
    isAdmin,
    asyncHandleNexInfo,
    handleStartRecord,
    errorFileUpload,
    asyncHandleUploadVideoWithoutInternet,
    LoadingSubmitUploadVideoWithoutInternet,
    listVideoUpload,
    deleteVideoUpload,
    isTutorial,
    handleCancel,
    handleRecordVideo,
    methods,
    recordVideo,
    listUser,
    user?.role,
    isCheckError
  ]);
  const postRecord = useCallback(
    async (value: FormRecord) => {
      const formData = new FormData();
      const fileDuration = storage.getFileDuration();
      if (step === 2) return setStep((prevState) => prevState + 1);
      if (!recordVideo || !fileDuration) return;
      const res = await fetch(recordVideo);
      const blob = await res.blob();
      const newBlob = new Blob([blob], { type: 'video/mp4' });
      const fName = `video-${dayjs().unix()}.mp4`;
      setFileName(fName);
      if (!navigator.onLine) {
        setFileBlob(newBlob);
        setModalSaveFile(true);
        const fileList = storage.getInfoQuestionsCookie();
        const newList = (
          fileList
            ? [
                ...fileList,
                {
                  fileName: fName,
                  title: value.title,
                  subjectId: value.subject,
                  userId: user?.role === ROLES.ADMIN ? String(value.user?.value) : undefined
                }
              ]
            : [
                {
                  fileName: fName,
                  title: value.title,
                  subjectId: value.subject,
                  userId: user?.role === ROLES.ADMIN ? String(value.user?.value) : undefined
                }
              ]
        ).map((item, index) => ({
          ...item,
          id: String(index + 1)
        }));
        storage.setInfoQuestionsCookie(newList);
        return;
      }
      formData.append('file', blob, fName);
      formData.append('fileDuration', fileDuration);

      const resRecord = await axios.post('/storages/questions', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      if (!resRecord.data) return;
      const payload = {
        title: value.title,
        explain: value.questions,
        subjectId: value.subject,
        isTutorial: value.isTutorial,
        recordId: resRecord.data?.record?.id ? Number(resRecord.data.record.id) : undefined,
        longitude: value.lng.toString(),
        latitude: value.lat.toString(),
        userId: user?.role === ROLES.ADMIN ? value.user?.value : undefined
      };
      await axios.post('/questions', payload);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        setIsOpenModal(true);
      }, 2500);
    },
    [recordVideo, step, user]
  );

  const handleSaveFile = useCallback(() => {
    if (!fileBlob) return;

    saveVideoFile(fileBlob, fileName);
    setModalSaveFile(false);
    navigate('/');
  }, [fileBlob, fileName, navigate]);

  const { asyncCallback: handlePostRecord } = useAsyncCallback(postRecord, []);

  return (
    <LayoutRole roles={[ROLES.ADMIN, ROLES.STUDENT]}>
      <Stack width={'100%'} padding={{ xs: '20px', md: '20px 70px 0 70px' }}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handlePostRecord)}>
            {renderStep()}
            {step !== 1 && (
              <Stack
                p={1.25}
                position={'relative'}
                overflow={'hidden'}
                zIndex={step === 2 ? 100 : 1}
                width={'100%'}
                justifyContent={'flex-end'}
                flexDirection={'row'}
                gap={2.5}>
                {recordVideo && (
                  <PrimaryButton
                    isOutline
                    label={'Cancel'}
                    onClick={handleCancel}
                    sx={{ width: 170, borderRadius: '12px' }}
                  />
                )}
                {recordVideo && (
                  <PrimaryButton
                    onClick={() => {
                      if (step === 3 && user?.role === ROLES.ADMIN) {
                        setIsCheckError(true);
                      }
                    }}
                    isLoading={loading}
                    type={'submit'}
                    label={step === 2 ? 'NEXT' : 'UPLOAD '}
                    icon={<img alt={'icon'} src={'/icons/icon_tree-flower.svg'} />}
                    sx={{ width: 170, borderRadius: '12px' }}
                  />
                )}
              </Stack>
            )}
          </form>
        </FormProvider>
        {success && (
          <Stack zIndex={9999}>
            <ExplodingAnimationFlower />
          </Stack>
        )}
        <DialogConfirm
          colorFlowerPoint={'#F22369'}
          isPlusPoint
          isOpen={isOpenModal}
          textButton={'Back to Home'}
          textTitle={'Uploaded Successfully'}
          textContent={'Keep an eye out under your saved videos for a response'}
          onSubmit={() => {
            setIsOpenModal(false);
            navigate('/');
          }}
          onClose={() => {
            setIsOpenModal(false);
            navigate('/');
          }}
        />
        <DialogConfirm
          isOpen={modalSaveFile}
          textButton={'Save Video'}
          textButtonClose={'Cancel'}
          textTitle={'Save Video'}
          textContent={
            'Due to an unstable internet connection, would you like to save the video to your device and upload it later when the connection is stable?'
          }
          onSubmit={handleSaveFile}
          onClose={() => {
            setModalSaveFile(false);
            navigate('/');
          }}
        />
      </Stack>
    </LayoutRole>
  );
};
