import { Grid, Stack, styled, Typography, useMediaQuery } from '@mui/material';
import { PrimaryButton } from 'app/components/elements/ButtonCustom';
import { ImageUser } from 'app/components/elements/ImageUser';
import { CustomPagination } from 'app/components/elements/Pagination';
import { SubjectAndFlowerText } from 'app/components/elements/SubjectAndFlowerText';
import { TruncateTableText } from 'app/components/elements/TruncateTableText';
import axios from 'axios';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { usePaging } from 'hooks/usePaging';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CityMapType } from 'types/map';
import { User } from 'types/User';
import { resolveParenthesesContent } from 'utils/resolveParenthesesContent';

export const ExploreDetails = () => {
  const isTablet = useMediaQuery('(max-width:900px)');
  // const isMobile = useMediaQuery('(max-width:460px)');
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [listUserCity, setListUserCity] = useState<User[]>();
  const [city, setCity] = useState<CityMapType>();

  const renderRowPage = useMemo(() => {
    if (isTablet) return 9;
    return 8;
  }, [isTablet]);

  const { pagingInfo, setCurrentPage, setTotal } = usePaging({
    rowsPerPage: renderRowPage
  });
  const handleChangePage = useCallback((value: number) => {
    setCurrentPage(value);
    setSearchParams({ page: value.toString() });
    // eslint-disable-next-line
  }, []);
  const { id } = useParams();

  const fetchDataListUser = useCallback(
    async (page: string) => {
      const res = await axios.get(`/explore/${id}?page=${page || 1}&size=${renderRowPage}`);
      setListUserCity(res.data.result.users as User[]);
      setCity(res.data.result.city as CityMapType);
      setCurrentPage(Number(page));
      setTotal(res.data.total);
      // eslint-disable-next-line
  }, [id]);
  const { asyncCallback: handleFetchData } = useAsyncCallback(fetchDataListUser, []);
  useEffect(() => {
    handleFetchData(searchParams.get('page') || 1);
    // eslint-disable-next-line
  }, [searchParams.get('page')]);
  return (
    <Wrapper py={!isTablet ? 15 : 3} px={!isTablet ? 3.5 : 2} gap={2} width={'100%'}>
      <TitleStyled>{resolveParenthesesContent(city?.city)}</TitleStyled>
      <Stack flexDirection={'row'} flexWrap={'wrap'} gap={2}>
        <Grid container spacing={2}>
          {listUserCity &&
            listUserCity?.map((item, index: number) => (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Stack
                  key={index}
                  onClick={() => {
                    navigate(`/profile/${item.id}`);
                  }}>
                  <ImageUser subjects={item.subjects} url={item.avatar} />
                  <Stack mt={1.25}>
                    <TruncateTableText
                      isPadding={false}
                      textProps={{ fontSize: { md: '18px', lg: '24px' }, fontWeight: 700 }}
                      text={item.name}
                      colorText={'#303972'}
                    />
                  </Stack>
                  <Stack flexDirection={'row'} alignItems={'center'} gap={1} flexWrap={'wrap'}>
                    {item.subjects.map((subject) => (
                      <SubjectAndFlowerText subjectId={subject.id} />
                    ))}
                  </Stack>
                  <Stack flexDirection={'row'} mt={1} gap={1.25}>
                    <Typography>{city?.flag}</Typography>
                    <Typography fontSize={'18px'} fontWeight={400} color={'#303972'}>
                      {city?.country},{resolveParenthesesContent(city?.city)}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            ))}
        </Grid>
      </Stack>
      <Stack
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent={'space-between'}
        alignItems={'center'}
        gap={{ xs: 2, md: 'unset' }}>
        <Stack width={'170px'} />
        {pagingInfo.pageCount !== 0 && (
          <CustomPagination
            page={pagingInfo.currentPage}
            count={pagingInfo.pageCount}
            onChangePage={handleChangePage}
          />
        )}
        <PrimaryButton
          isOutline
          label={'BACK'}
          style={{ width: 170, height: 61 }}
          onClick={() => navigate('/explore')}
          iconLeft={<img alt={'button-img'} src={'/icons/icon_back.svg'} />}
        />
      </Stack>
    </Wrapper>
  );
};

const Wrapper = styled(Stack)(() => ({}));

const TitleStyled = styled(Typography)(() => ({
  fontSize: '34px',
  fontWeight: 700,
  lineHeight: 'normal',
  color: '#770BA1'
}));
