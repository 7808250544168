import { Stack, Typography } from '@mui/material';
import { SecondaryButton } from 'app/components/elements/ButtonCustom';
import { useLoading } from 'contexts/loading';
type FormQuestionProps = {
  onYes: () => void;
  onNo: () => void;
  isMobile: boolean;
};
export const FormQuestion = ({ onYes, onNo, isMobile }: FormQuestionProps) => {
  const { loading } = useLoading();
  return (
    <Stack width={'100%'} alignItems={'center'} mt={{ xs: '130px', md: 'unset' }}>
      <Stack maxWidth={isMobile ? '100%' : 400} gap={'17px'}>
        <Typography color={'#839E21'} fontWeight={700} fontSize={'20px'}>
          Would you like to be a Bloombassador?
        </Typography>
        <Typography whiteSpace={'pre-line'} color={'#839E21'} fontWeight={400}>
          Bloombassadors are the heart of SuperBloom!{'\n'} If you are 18y or older and studying at
          college, you’re invited to be a Bloombassador! As a Bloombassador, you become a mentor to
          younger students who share your interests and who are excited about asking big questions.
          Your responses to videos from around the world can transform the educational experiences
          of students, and in turn, you can earn service hours and designations from BloomBox Design
          Labs. We would love for YOU to take on this leadership role!
        </Typography>
        <Stack
          width={'100%'}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          mt={2.375}
          gap={isMobile ? '20px' : 'unset'}>
          <SecondaryButton
            isLoading={loading}
            colorButton={'#770BA1'}
            onClick={onYes}
            label={'Yes'}
            style={{ width: isMobile ? '100%' : 193 }}
          />
          <SecondaryButton
            isLoading={loading}
            colorButton={'#770BA1'}
            isOutline
            label={'No'}
            onClick={onNo}
            style={{ width: isMobile ? '100%' : 193 }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
