import { LinearProgress, Stack, useMediaQuery } from '@mui/material';
import { PrimaryButton } from 'app/components/elements/ButtonCustom';
import { DialogConfirm } from 'app/components/elements/DialogConfirm';
import { Logo } from 'app/components/elements/Logo';
import { LayoutLanding } from 'app/components/templates/LayoutLanding';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const isMobile = useMediaQuery('(max-width:900px)');
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleVerify = useCallback(async (token: string) => {
    await axios.post('/auth/verify-account', { token });
    setShowModal(true);
  }, []);
  const { asyncCallback, isLoading } = useAsyncCallback(handleVerify, []);

  useEffect(() => {
    const token = searchParams.get('token');
    if (token) {
      asyncCallback(token);
    }
  }, [searchParams.get('token')]);

  const handleClose = useCallback(() => {
    setShowModal(false);
    navigate('/login');
  }, []);

  return (
    <Stack alignItems={'center'} width={'100%'}>
      <LayoutLanding bg={isMobile ? '/images/bg-landing-mobile-1.png' : '/images/bg-landing-1.png'}>
        {isLoading && <LinearProgress />}
        <Stack flex={1} alignItems={'center'} justifyContent={'center'} gap={10}>
          <Logo />
          <PrimaryButton
            label={'Go to Login'}
            onClick={() => navigate('/login')}
            fontSizeText={'20px'}
            style={{ width: 200 }}
          />
        </Stack>
        <DialogConfirm
          isOpen={showModal}
          textButton={'Go to Login'}
          textTitle={'Verify Successful'}
          textContent={'Authentication successful, please login again.'}
          onSubmit={handleClose}
          onClose={handleClose}
        />
      </LayoutLanding>
    </Stack>
  );
};
