import { Stack, Typography } from '@mui/material';

export const Logo = () => {
  return (
    <Stack alignItems={'center'}>
      <Stack width={{ sm: '370px', xs: '247px' }}>
        <img width={'100%'} height={'100%'} alt={'logo'} src={'/images/logo-login.png'} />
      </Stack>
      <Typography fontSize={'26px'} color={'#770BA1'}>
        by BloomBox Design Labs
      </Typography>
    </Stack>
  );
};
