import { Stack } from '@mui/material';
import { InputCustom } from 'app/components/elements/InputCustom';
import { Logo } from 'app/components/elements/Logo';
import { useFormContext } from 'react-hook-form';
import { TypeFormSignUp } from 'app/pages/Introduction';
import { TextError } from 'app/components/elements/TextError';

type FormNameProps = {
  isMobile: boolean;
  activeStep: number;
};
export const FormName = ({ isMobile, activeStep }: FormNameProps) => {
  const {
    register,
    formState: { errors }
  } = useFormContext<TypeFormSignUp>();
  return (
    <Stack minWidth={isMobile ? '100%' : '402px'} alignItems={'center'} gap={'19px'}>
      <Logo />
      <Stack width={isMobile ? '100%' : '403px'} gap={1}>
        <InputCustom
          isCenter={!isMobile}
          label={'What is your name?'}
          inputProps={{
            ...register('name', {
              required: { value: activeStep === 1, message: 'Required Field' }
            }),
            placeholder: 'Your name',
            style: { width: isMobile ? '100%' : '403px' }
          }}
        />
        <TextError text={errors?.name?.message || ''} />
      </Stack>
    </Stack>
  );
};
