import { Grid, Stack, styled, Typography } from '@mui/material';
import { PrimaryButton } from 'app/components/elements/ButtonCustom';
import { InputSelectMultiple } from 'app/components/elements/InputSelectMultiple';
import { CustomPagination } from 'app/components/elements/Pagination';
import { RecordUserCard } from 'app/components/elements/RecordUserCard';
import axios from 'axios';
import { renderListSubject } from 'constants/renderListSubject';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { usePaging } from 'hooks/usePaging';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Questions } from 'types/questions';

const convertQuery = (subjects: (number | string | undefined)[], page?: string) => {
  const queryStringArray = subjects.map((subject) => {
    if (!subject) return;
    return `subjects=` + encodeURIComponent(subject);
  });
  return `page=${page || 1}&${queryStringArray.join('&')}`;
};
const SIZE_PAGE = 8;

export const SearchPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [dataPost, setDataPost] = useState<Questions[]>([]);

  const { pagingInfo, setCurrentPage, setTotal } = usePaging({
    rowsPerPage: SIZE_PAGE
  });
  const [subjects, setSubjects] = useState<string[]>(searchParams.getAll('subjects'));

  const fetchListPost = useCallback(
    async (page: string) => {
      const query = convertQuery(subjects, page);
      const res = await axios.get(`/questions?size=${SIZE_PAGE}&${query}`);
      setDataPost(res.data.result as Questions[]);
      setCurrentPage(Number(page));
      setTotal(res.data.total);
      // eslint-disable-next-line
    }, [subjects]);

  const { asyncCallback: fetchDataList, isLoading } = useAsyncCallback(fetchListPost, []);

  const handleChangeSubject = useCallback((value: string[]) => {
    setSubjects(value);
  }, []);
  useEffect(() => {
    fetchDataList(searchParams.get('page'));
  }, [searchParams.get('page')]);

  const handleChangePage = useCallback((value: number) => {
    setCurrentPage(value);
    setSearchParams({ page: value.toString() });
    // eslint-disable-next-line
  }, []);

  const handleSearch = useCallback(() => {
    setCurrentPage(1);
    const query = convertQuery(subjects);
    setSearchParams(query);
    fetchDataList(1);
  }, [subjects]);

  return (
    <Stack
      pb={10}
      pt={{ xs: 2.5, md: 12.5 }}
      pl={{ xs: 2.5, md: 7.25 }}
      pr={{ xs: 2.5, md: 7.25 }}
      minWidth={'100%'}>
      <TextStyled fontSize={{ xs: '24px', md: '34px' }}>Search for topic</TextStyled>
      <TextStyled mt={3.75} mb={1.25} fontSize={{ xs: '20px', md: '24px' }}>
        I’m interested in...
      </TextStyled>
      <Stack
        width={'100%'}
        flexDirection={'row'}
        gap={{ xs: 1.25, md: 3.25 }}
        mb={{ xs: 3, md: 9.25 }}>
        <Stack width={{ xs: '100%', md: '760px' }}>
          <InputSelectMultiple
            defaultValue={subjects}
            onChangeInput={handleChangeSubject}
            placeholder={'Select subject'}
            options={renderListSubject()}
            inputProps={{
              color: '#686868'
            }}
          />
        </Stack>
        <PrimaryButton
          isLoading={isLoading}
          style={{ width: 164, borderRadius: '12px' }}
          onClick={handleSearch}
          label={'Search'}
          isIconFlower
        />
      </Stack>
      <Stack flexDirection={'row'} flexWrap={'wrap'} gap={2}>
        <Grid container spacing={1}>
          {dataPost?.map((item, index: number) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <RecordUserCard
                createAt={item.createdAt}
                isTutorial={item.isTutorial}
                questionsId={item.id}
                comments={item.comments}
                isQuestions
                url={item.record.url}
                title={item.title}
                subjectId={item.subject.id}
              />
            </Grid>
          ))}
        </Grid>
        <Stack
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent={'center'}
          width={'100%'}
          alignItems={'center'}
          gap={{ xs: 2, md: 'unset' }}>
          {pagingInfo.pageCount !== 0 && (
            <CustomPagination
              page={pagingInfo.currentPage || 1}
              count={pagingInfo.pageCount}
              onChangePage={handleChangePage}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

const TextStyled = styled(Typography)(() => ({
  fontWeight: 600,
  color: '#770BA1',
  lineHeight: 'normal'
}));
