import { Stack, useMediaQuery } from '@mui/material';
import { Logo } from 'app/components/elements/Logo';

import { LayoutLanding } from 'app/components/templates/LayoutLanding';
import { ResetPasswordForm } from 'app/pages/ResetPassword/components/resetPasswordForm';

export const ResetPasswordPage = () => {
  const isMobile = useMediaQuery('(max-width:900px)');

  return (
    <Stack alignItems={'center'} width={'100%'}>
      <LayoutLanding bg={isMobile ? '/images/bg-landing-mobile-1.png' : '/images/bg-landing-1.png'}>
        <Stack mt={8.375} mb={3.5} width={'100%'} alignItems={'center'}>
          <Logo />
          <Stack
            px={{ xs: 3.125, sm: 0 }}
            mt={3}
            width={'100%'}
            maxWidth={{ xs: 'unset', sm: 402 }}>
            <ResetPasswordForm />
          </Stack>
        </Stack>
      </LayoutLanding>
    </Stack>
  );
};
