import { Grid, Stack, useMediaQuery } from '@mui/material';
import { CustomPagination } from 'app/components/elements/Pagination';
import { RecordUserCard } from 'app/components/elements/RecordUserCard';
import axios from 'axios';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { usePaging } from 'hooks/usePaging';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Answer } from 'types/questions';

type QuestionsTabProps = {
  userId?: string;
};
export const ResponsesTab = ({ userId }: QuestionsTabProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isTablet = useMediaQuery('(max-width:900px)');
  const [listResponses, setListResponses] = useState<Answer[]>([]);

  const renderRowPage = useMemo(() => {
    if (isTablet) return 9;
    return 8;
  }, [isTablet]);

  const { pagingInfo, setCurrentPage, setTotal } = usePaging({
    rowsPerPage: renderRowPage
  });

  const handleChangePage = useCallback((value: number) => {
    setCurrentPage(value);
    setSearchParams({ tab: 'responses', page: value.toString() });
    // eslint-disable-next-line
  }, []);

  const fetchDataListUser = useCallback(
    async (page: string) => {
      if (!userId) return;
      const res = await axios.get(
        `/answers/users/${userId}?page=${page || 1}&size=${renderRowPage}`
      );
      setListResponses(res.data.result as Answer[]);
      setCurrentPage(Number(page));
      setTotal(res.data.total);
      // eslint-disable-next-line
    }, [userId]);
  const { asyncCallback: handleFetchData } = useAsyncCallback(fetchDataListUser, []);
  useEffect(() => {
    if (!userId) return;
    handleFetchData(searchParams.get('page') || 1);
    // eslint-disable-next-line
  }, [searchParams.get('page'), userId]);

  return (
    <Stack flexDirection={'row'} flexWrap={'wrap'} gap={2}>
      <Grid container spacing={1}>
        {listResponses?.map((item, index: number) => (
          <Grid item xs={6} md={4} lg={3} xl={3} key={index}>
            <RecordUserCard
              createAt={item.createdAt}
              url={item.record.url}
              title={item.title}
              subjectId={item.question.subjectId}
            />
          </Grid>
        ))}
      </Grid>
      <Stack
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent={'center'}
        width={'100%'}
        alignItems={'center'}
        gap={{ xs: 2, md: 'unset' }}>
        {pagingInfo.pageCount !== 0 && (
          <CustomPagination
            page={pagingInfo.currentPage}
            count={pagingInfo.pageCount}
            onChangePage={handleChangePage}
          />
        )}
      </Stack>
    </Stack>
  );
};
