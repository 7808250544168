import { RequireAuth } from 'app/components/modules/PrivateRouter/requireAuth';
import { Layout } from 'app/components/templates/Layout';
import { AdminAnswerPage } from 'app/pages/AdminAnswerPage';
import { AdminCommentsPage } from 'app/pages/AdminCommentsPage';
import { AdminQuestionsPage } from 'app/pages/AdminQuestionsPage';
import { AdminUserPage } from 'app/pages/AdminUserPage';
import { DashboardPage } from 'app/pages/DashboardPage';
import { ProfilePage } from 'app/pages/ProfilePage';
import { UserProfilePage } from 'app/pages/ProfilePage/user';
import { ExploreDetails } from 'app/pages/ExplorePage/ExploreDetails';
import { HomePage } from 'app/pages/HomePage';
import { NotFoundPage } from 'app/pages/NotFoundPage';
import { LoginPage } from 'app/pages/LoginPage';
import { ForgotPasswordPage } from 'app/pages/ForgotPasswordPage';
import { VerifyEmail } from 'app/pages/RegisterEmail';
import { ResetPasswordPage } from 'app/pages/ResetPassword';
import { SearchPage } from 'app/pages/SearchPage';
import { SignUpPage } from 'app/pages/SignUp';
import { Introduction, ROLES } from 'app/pages/Introduction';
import { useAuth } from 'contexts/user';
import { Route, Routes } from 'react-router-dom';
import { RecordPage } from 'app/pages/RecordPage';
import { ExplorePage } from 'app/pages/ExplorePage';
import { QuestionsPage } from 'app/pages/DashboardPage/QuestionsPage';
import { AnswerPage } from 'app/pages/DashboardPage/AnswerPage';

export default function App() {
  const { user } = useAuth();
  return (
    <Routes>
      <Route
        path="/*"
        element={
          <RequireAuth>
            <Layout>
              <Routes>
                <Route
                  path=""
                  element={user?.role === ROLES.ADMIN ? <AdminUserPage /> : <HomePage />}
                />
                <Route path="record" element={<RecordPage />} />
                <Route path="search" element={<SearchPage />} />
                <Route path="explore" element={<ExplorePage />} />
                <Route path="explore/:id" element={<ExploreDetails />} />
                <Route path="dashboard" element={<DashboardPage />} />
                <Route path="dashboard/questions/:id" element={<QuestionsPage />} />
                <Route path="dashboard/answer/:id" element={<AnswerPage />} />
                <Route path="profile" element={<ProfilePage />} />
                <Route path="profile/:id" element={<UserProfilePage />} />
                <Route path="questions" element={<AdminQuestionsPage />} />
                <Route path="answers" element={<AdminAnswerPage />} />
                <Route path="comments" element={<AdminCommentsPage />} />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </Layout>
          </RequireAuth>
        }
      />
      <Route path="/sign-up" element={<SignUpPage />} />
      <Route path="/verify-account" element={<VerifyEmail />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/introduction" element={<Introduction />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
