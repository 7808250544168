import axios from 'axios';
import { InfoUserProvider, useAuth } from 'contexts/user';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { configureAxios } from 'config/axios';

// Use consistent styling
import 'sanitize.css/sanitize.css';
import { ThemeProvider } from '@mui/material';
import theme from 'styles/theme';
import 'styles/global.css';
import { FlashProvider } from 'contexts/flash';
import { LoadingProvider } from 'contexts/loading';
import { Flash } from 'app/components/elements/Flash';
import App from 'app';
import { BrowserRouter } from 'react-router-dom';

// Initialize axios
configureAxios();

const firebaseConfig = {
  apiKey: 'AIzaSyBm3kmD4u6CtwY3JS-d7MNY0RC1v8GoV1Q',
  authDomain: 'superbloom-pwa.firebaseapp.com',
  projectId: 'superbloom-pwa',
  storageBucket: 'superbloom-pwa.appspot.com',
  messagingSenderId: '837140597621',
  appId: '1:837140597621:web:2714ad106c33ec340b213c',
  measurementId: 'G-E88R4NPD95'
};

const GetPermission = () => {
  const { user, setUser } = useAuth();
  const { asyncCallback: postDeviceId } = useAsyncCallback(
    useCallback(async (deviceId: string) => {
      await axios.post('/users/devices', { deviceId });
    }, []),
    []
  );
  const requestPermission = useCallback(async () => {
    const app = initializeApp(firebaseConfig);
    const isSp = await isSupported();
    if (!isSp) return;
    const messaging = getMessaging(app);
    getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIRE_BASE_V_API_KEY
    }).then((currentToken) => {
      console.log(currentToken);
      if (currentToken && user) {
        postDeviceId(currentToken);
        setUser({ ...user, deviceId: currentToken });
      } else {
        console.log('Can not get token');
      }
    });
    if ('Notification' in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          onMessage(messaging, (payload) => {
            const dataNotifee = payload?.data?.notifee
              ? JSON.parse(payload?.data?.notifee as string)
              : '';
            const notification = new Notification(payload.notification?.title || '', {
              body: payload.notification?.body,
              icon: '/favicon.png'
            });

            notification.onclick = (event) => {
              event.preventDefault();
              if (dataNotifee?.url?.includes('https://superbloom-gen.com')) {
                window.location.href = dataNotifee.url;
                return;
              }
              window.open(dataNotifee.url, '_blank');
            };
          });
        } else {
          console.log('Do not have permission!');
        }
      });
      return;
    }
    onMessage(messaging, (payload) => {
      alert(`${payload.notification?.title}\n${payload.notification?.body}`);
    });
  }, [postDeviceId, user]);

  useEffect(() => {
    if (!user?.id) return;
    if (user?.id && !user?.deviceId) {
      requestPermission();
    }
  }, [user?.id]);

  return null;
};

/**
 * Root App
 */
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Helmet defaultTitle="SUPERBLOOM" titleTemplate="SUPERBLOOM">
        <meta content="A React Boilerplate application" name="description" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <FlashProvider>
          <LoadingProvider>
            <InfoUserProvider>
              <GetPermission />
              <App />
            </InfoUserProvider>
          </LoadingProvider>
          <Flash />
        </FlashProvider>
      </ThemeProvider>
    </BrowserRouter>
  </HelmetProvider>
);

serviceWorkerRegistration.register();
