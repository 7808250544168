import { Stack, Typography } from '@mui/material';
import { DialogPreviewRecord } from 'app/components/elements/DialogPreviewRecord';
import { RecordUser } from 'app/components/elements/RecordUser';
import { SubjectAndFlowerText } from 'app/components/elements/SubjectAndFlowerText';
import { TruncateTableText } from 'app/components/elements/TruncateTableText';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Comment } from 'types/questions';
import { renderColorSubject } from 'utils/renderColorSubject';
type RecordUserCardProps = {
  title: string;
  url: string;
  subjectId?: string;
  isQuestions?: boolean;
  comments?: Comment[];
  questionsId?: string;
  isTutorial?: boolean;
  createAt?: string;
};
export const RecordUserCard = ({
  title,
  subjectId,
  url,
  isQuestions,
  comments,
  questionsId,
  isTutorial,
  createAt
}: RecordUserCardProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <Stack gap={1} alignItems={{ xs: 'center', md: 'flex-start' }}>
      <Stack pr={2.5}>
        <TruncateTableText
          isPadding={false}
          textProps={{ fontSize: '24px', fontWeight: 700 }}
          colorText={renderColorSubject(Number(subjectId))?.color || '#697D21'}
          text={title}
        />
      </Stack>
      <Stack
        sx={{ cursor: 'pointer' }}
        width={'100%'}
        height={'auto'}
        onClick={() => setIsOpen(true)}>
        <RecordUser isTutorial={isTutorial} subjectId={subjectId} url={url} />
      </Stack>
      <Stack
        width={'100%'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={{ md: 'flex-start', xs: 'center' }}
        pr={1.25}>
        <SubjectAndFlowerText subjectId={subjectId} />
        <Typography
          style={{
            color: renderColorSubject(Number(subjectId))?.color,
            fontSize: 16,
            fontWeight: 700
          }}>
          {dayjs(createAt).format('YYYY-MM-DD')}
        </Typography>
      </Stack>
      <DialogPreviewRecord
        questionsId={questionsId}
        comments={comments}
        isQuestion={isQuestions}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        url={url}
        subjectId={subjectId || ''}
        isTutorial={isTutorial}
      />
    </Stack>
  );
};
